import React from 'react'
import styled, { css } from 'styled-components'

import StyledButton from "../shared/StyledButton";

import {media} from "utils/Media"

const NewSaleStyles = styled.section`
    width: 100%;
    padding: 3rem 0 2rem 0;
    h2 {
        text-align: center;
        font-size: 3rem;
        color: #fff;
        font-style: italic;
    }

    .fees {
        text-align: center;
        font-weight: 700;
        color: #fff;
    }
`

const CustomBox = styled.div`
  background: linear-gradient(to bottom,
  ${(props) => props.theme.colors.red},
  ${(props) => props.theme.colors.yellow});
  padding: 8px;
  display: block;
  max-width: 1200px;
  margin: 0 auto;

  .inner {
    background: ${(props) => props.theme.colors.black};
    padding: 1rem 3rem ;
    .lower {
        text-align: center;
        color: #fff;
        font-style: italic;
        font-size: 15px;
    }
    .homepage {
        p {
            text-align: center;
            color: #fff;
            font-weight: 700;
            margin: 1.5rem 0;
        }
        .btn {
            margin: 0 auto;
            text-align: center;
        }
    }
    .grid {
        margin-top: 2rem;
        display: grid;
        grid-gap: 2rem;
        @media ${media.md} {
            max-width: 900px;
            margin: 2rem auto;
            grid-template-columns: repeat(2, 1fr);
        }
        .item {
            h5 {
                color: #fff;
                text-align: center;
                font-weight: 900;
                letter-spacing: 1px;
                margin-bottom: 1rem;
                font-size: 1.85rem;
            }
            p {
                text-align: center;
                color: #fff;
                font-weight: 800;
                margin-top: 1.5rem;
            }
            .info {
                font-size: 14px;
                font-style: italic;
                font-weight: 600;
                padding-right: 5px;
            }
            .price {
                font-size: 3rem;
          
        }
            
            
        }
    }
  }
`;
export default function NewSaleTicketBlock({ homepage }) {
  return (
    <NewSaleStyles>
        <CustomBox>
        <div className='inner'>
            <h2>The Back In Time Fall Sale</h2>
            {homepage && (
                <div className="homepage">
                    <p>GET YOUR TICKETS YESTERDAY!</p>
                    <div className="btn">

                    <StyledButton
              target="_blank"
              rel=" noopener"
              href="/new-york/tickets/"
              >
              Book Now
            </StyledButton>
                </div>
                </div>
            )}
            {!homepage && (
<>
                <div className='grid'>
                <div className='item'>
                    <h5>UPPER LEVEL Seats</h5>
                    <p>
                        <span className='info'>starting at</span><span className='price gradient-text'>$59</span>
                    </p>
                </div>
                <div className='item'>
                    <h5>Stage Level Seats</h5>
                    <p> 
                    <span className='info'>starting at</span><span className='price gradient-text'>$79</span>
                    </p>
                </div>
            </div>
            <p className="fees">Click the highlighted performances below to book</p>
            <p className="fees">ALL FEES INCLUDED</p>
            <p className='lower'>Valid for all performances from 9/17 to 11/10, excluding the 10/11 - 10/13.
</p>
</>
            )}
        </div>
      </CustomBox>
    </NewSaleStyles>
  )
}
